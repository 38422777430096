import { Injectable } from '@angular/core';
import { Geolocation, Geoposition, GeolocationOptions } from '@ionic-native/geolocation/ngx';

import { takeUntil } from 'rxjs/operators';
import { Subject, interval } from 'rxjs';

import { ILocation } from '../domain';
import { AlertController } from '@ionic/angular';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Geocoder, GeocoderResult } from '@ionic-native/google-maps/ngx';
import { Constants } from '../shared/constants';
import { distanceBetween } from '../utils';
import { PreferenceService } from './preference.service';
import { MapsAPILoader } from '@agm/core';


declare let google: any;

/**
 * Event published when the selected map location is changed
 */
export class MapViewportEvent {
  constructor(private _mapCenter: ILocation, private _radius: number) {
  }

  get mapCenter(): ILocation {
    return this._mapCenter;
  }

  set mapCenter(value: ILocation) {
    this._mapCenter = value;
  }

  get radius(): number {
    return this._radius;
  }

  set radius(value: number) {
    this._radius = value;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  locationChanged: Subject<ILocation> = new Subject();
  mapViewportChanged: Subject<MapViewportEvent> = new Subject();
  lastMapViewportEvent: MapViewportEvent;

  private geocoder: any;

  get currentLocation(): ILocation {
    return { latitude: 30.18697753702476, longitude: -81.39885641634464 };
    // return JSON.parse(localStorage.getItem(Constants.STORAGE_KEY_LOCATION));
  }

  set currentLocation(latlng: ILocation) {
    localStorage.setItem(Constants.STORAGE_KEY_LOCATION, JSON.stringify(latlng));
    this.locationChanged.next(latlng);
  }

  constructor(
    private geolocation: Geolocation,
    private diagnostic: Diagnostic,
    private alertCtrl: AlertController,
    private preferenceService: PreferenceService,
    private mapsAPILoader: MapsAPILoader
  ) {
  }

  async initialize() {
    if (this.currentLocation) {
      return this.locationChanged.next(this.currentLocation);
    }

    try {
      await this.getGeoposition();
    } catch (e) {
      if (e.code && e.code === 1) {
        const location = await this.getLocationFromPostalCode();
        if (location) {
          this.currentLocation = location;
        }
      } else {
        const maxT = new Subject();
        interval(1000)
            .pipe(takeUntil(maxT))
            .subscribe(async timer => {

              if (timer > 120) {
                maxT.next();
              }

              try {
                await this.getGeoposition();
              } catch (e) {
                console.log('*** Permission denied ***', e);
              } finally {
                const permission = await this.diagnostic.getLocationAuthorizationStatus();

                switch (permission) {
                  case this.diagnostic.permissionStatus.GRANTED:
                  case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
                    maxT.next();
                    break;
                  case this.diagnostic.permissionStatus.DENIED:
                  case this.diagnostic.permissionStatus.DENIED_ALWAYS:
                    maxT.next();
                    const location = await this.getLocationFromPostalCode();
                    if (location) {
                      this.currentLocation = location;
                    }
                    break;
                  default:
                    break;
                }
              }
            });
      }
    }
  }

  async getGeoposition(): Promise<Geoposition> {
    try {
      const geoPos = await this.geolocation.getCurrentPosition();
      if (geoPos) {
        const { latitude, longitude, altitude, accuracy } = geoPos.coords;
        this.currentLocation = { latitude, longitude, altitude, accuracy, ...{timestamp: geoPos.timestamp} };
        return geoPos;
      }
    } catch (e) {
      console.error(`*** Failed to get location ${JSON.stringify(e)} ***`);
      throw e;
    }
  }

  setMapViewport(latlng: ILocation, radius: number) {
    const event = new MapViewportEvent(latlng, radius);
    this.lastMapViewportEvent = event;
    this.mapViewportChanged.next(event);
  }

  getLastMapLocation(): ILocation {
    return this.lastMapViewportEvent ? this.lastMapViewportEvent.mapCenter : this.currentLocation;
  }

  /**
   * Creates a single watch location subscription.  If a subscription exists, an exception is raised
   */
  watchPosition(untilFn: Subject<any>,
                options: GeolocationOptions = { maximumAge: 10000, timeout: 30000, enableHighAccuracy: true }) {

    this.geolocation
        .watchPosition(options)
        .pipe(takeUntil(untilFn))
        .subscribe(async p => {
          if (p.coords) {
            const { latitude, longitude, altitude, accuracy } = p.coords;
            const timestamp = p.timestamp;
            const newLocation = { latitude, longitude, altitude, accuracy, timestamp};

            if (this.currentLocation) {
              const dist = distanceBetween(this.currentLocation, newLocation);
              const tDelta = (timestamp - this.currentLocation.timestamp) / 1000 / 60;

              const config = await this.preferenceService.getConfig();

              /**
               * Reload all the data only if the activePosition has changed significantly or a minimum amount of time
               * since the last load has passed.  This is to reduce data transmission volumes and improve performance.
               */
              if (dist >= Constants.OFFERS_LOAD_DISTANCE || tDelta >= config.locUpdateFreq) {
                this.currentLocation = newLocation;
              }
            } else {
              this.currentLocation = newLocation;
            }

            return p.coords;
          }
      });
  }


  private async getLocationFromPostalCode(): Promise<ILocation | null> {
    const message = `A location is required to find nearby offers. Enter a postal code, we can use that!`;
    const postalCode = await this.openPostalDialog(message);

    if (!postalCode) {
      return null;
    }

    const geoResult = await Geocoder.geocode({address: postalCode}) as GeocoderResult[];
    if (geoResult.length) {
      const { lat, lng } = geoResult[0].position;

      return {
        latitude: lat,
        longitude: lng,
        timestamp: Date.now()
      };
    } else {
      const position = await this.getGeoposWithJavascriptAPI(postalCode);

      if (position) {
        return position;
      }

      const alert = await this.alertCtrl.create({
        header: 'Not Found',
        subHeader: 'Correct the postal code and retry',
        buttons: [{
          text: 'Ok',
          role: 'ok'
        }]
      });

      await alert.present();

      const { role } = await alert.onDidDismiss();

      if (role === 'ok') {
        return this.getLocationFromPostalCode();
      }

      return null;
    }

  }

  /**
   *
   * Geocoding with GoogleMaps Javascript SDK
   *
   * @param address : string
   */
  private async getGeoposWithJavascriptAPI(address: string): Promise<ILocation> {
    if (!this.geocoder) {
      await this.mapsAPILoader.load();
      this.geocoder = new google.maps.Geocoder();
    }

    try {
      const geoResults: any[] = await new Promise((resolve, reject) => {
        this.geocoder.geocode({address}, (result, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            console.log('Geocodig complete!');

            resolve(result.map(r => {
              const { location } = r.geometry;
              return {
                latitude: location.lat(),
                longitude: location.lng(),
                timestamp: Date.now()
              };
            }));
          } else {
            console.log('Geocoding fails!', status);

            reject(status);
          }
        });
      });

      return geoResults && geoResults.length ? geoResults[0] : null;
    } catch (e) {
      console.log(e);

      return null;
    }

  }

  private async openPostalDialog(message: string): Promise<string | null> {
    const prompt = await this.alertCtrl.create({
      header: 'Set a Location',
      message: message,
      backdropDismiss: false,
      inputs: [{
        placeholder: 'Postal Code',
        name: 'postalCode'
      }],
      buttons: [{
        text: 'Ok'
      }, {
        text: 'Cancel',
        role: 'cancel'
      }]
    });

    prompt.present();

    const { data, role } = await prompt.onDidDismiss();
    return role !== 'cancel' ? data['values'].postalCode : null;
  }

}
