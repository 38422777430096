import { Component } from '@angular/core';
import { CommonModalPage } from 'src/app/shared/components/common.modal';

@Component({
  selector: 'scan-modal-redeem',
  templateUrl: './scan.redeem.modal.html',
  styleUrls: ['./scan.redeem.modal.scss'],
})
export class ScanRedeemModal extends CommonModalPage {
  offerId: number;
  redeemerId: number;
  shareId: string;

  constructor() {
    super();
  }

  createQRValue(): string {
    let qrValue = this.offerId + ':' + this.redeemerId;

    if (this.shareId) {
      qrValue  += ':' + this.shareId;
    }

    return qrValue;
  }
}
