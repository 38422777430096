import { Injectable } from '@angular/core';

import { LocationService } from './location.service';
import { CommonApi } from '../shared/services/common.api';
import { generateShareId } from '../utils';
import { Contact } from '@ionic-native/contacts/ngx';
import { ShareService } from './share.service';
import { AuthService } from './auth.service';
import { PreferenceService } from './preference.service';

import {
  IOffer,
  IOfferCategory,
  ISpreaderOffer,
  SharePlatform,
  IOfferShare,
  ShareType,
  shareOptions,
  ILocation,
  IBusiness
} from '../domain';
import { DynamicLinkService } from './dynamic.link.service';
import { Constants } from '../shared/constants';
import { HttpHeaders } from '@angular/common/http';
import { IOfferShareResults } from '../domain/offer-share-results';

export type OffersFilter = 'trending' | 'nearby' | 'category';


@Injectable({
  providedIn: 'root'
})
export class OfferService extends CommonApi<IOffer> {
  sharedHistory: IOfferShare[] = [];

  get offerToFavorite() {
    return JSON.parse(localStorage.getItem('offer_to_save'));
  }

  set offerToFavorite(offer: any) {
    localStorage.setItem('offer_to_save', JSON.stringify(offer));
  }

  constructor(
    private locationService: LocationService,
    private shareService: ShareService,
    private authService: AuthService,
    private preferenceService: PreferenceService,
    private dynamicLinkService: DynamicLinkService
  ) {
    super();
  }

  /**
   * Send a request to the system for redeeming
   *
   * @param offerId
   * @param redeemerId
   * @param shareId
   */
  async submitRedeemRequest(offerId: number, notes: string) {
    const location = this.locationService.currentLocation;

    const params = {};
    params['oid'] = offerId;
    params['notes'] = notes;
    params['latitude'] = location.latitude;
    params['longitude'] = location.longitude;

    const endpoint = 'spreader/business/submit_redeem_request';
    return this.request('POST', endpoint, { params });
  }

  /**
   * This inserts a redemption record in the database as though the coupon had
   * actually been redeemed by the the merchant. This
   * if for tracking redemtions of offers from unclaimed businesses
   *
   * @param offerId
   * @param redeemerId
   */
  async redeemCoupon(offerId: number) {
    const location = this.locationService.currentLocation;

    const params = {};
    params['oid'] = offerId;
    params['latitude'] = location.latitude;
    params['longitude'] = location.longitude;

    const endpoint = 'spreader/business/redeem_coupon';
    return this.request('POST', endpoint, { params });
  }


  /**
   * Checks for a pending redeem request by the user
   * @param offerId
   */
  async redeemRequestPending(offerId: number) {
    const params = {};
    params['oid'] = offerId;

    const endpoint = 'spreader/business/is_redemption_pending';
    return this.request('GET', endpoint, { params });
  }

  /**
   * Gets the set of offer categories available at this current location
   *
   */
  async getOfferCategories(location?: ILocation, searchRadius?: number) {
    const params = location || this.locationService.currentLocation;
    if (params) {
      params.distance = searchRadius || await this.preferenceService.getConfig().searchRadius;
    }

    return this.request<IOfferCategory[]>('GET', 'offers/nearby/categories', { params: params });
  }

  async getOffers(filter?: OffersFilter, options: {
      categoryId?: string,
      nearestOnly?: boolean,
      excludeFavorited?: boolean,
      location?: ILocation,
      searchRadius?: number
    } = {}
  ) {
    const location = options.location || this.locationService.currentLocation;
    const distance = options.searchRadius || (await this.preferenceService.getConfig()).searchRadius;

    const params = {...location, distance};

    let endpoint: string;

    switch (filter) {
      case 'trending':
        params[Constants.PARAM_NAME_CACHE_ENABLE] = true;
        endpoint = 'offers/trending/nearby';
        break;

      case 'nearby':
        if (this.authService.isLoggedIn()) {
          endpoint = 'spreader/offers/nearby';
          params['nearest-only'] = options.nearestOnly || true;
          params['exclude-favs'] = options.excludeFavorited || true;
        } else {
          endpoint = 'offers/nearby';
          params['nearest-only'] = options.nearestOnly;
        }
        break;

      case 'category':
        if (!options.categoryId) {
          return [];
        }

        endpoint = 'offers/nearby/in_category';
        params['catId'] = options.categoryId;
        break;

      default:
        break;
    }

    return this.request<IOffer[]>('GET', endpoint, { params: params });

  }

  /**
   * Get saved offers
   */
  async getSavedOffers() {
    const location = this.locationService.currentLocation;

    const params = location;

    return this.request<ISpreaderOffer[]>('GET', 'spreader/offers/saved', { params });
  }

  /**
   * Get purchased rewards
   */
  async getPurchasedRewards() {
    const location = this.locationService.currentLocation;

    return this.request<ISpreaderOffer[]>('GET', 'spreader/rewards', { params: location });
  }


  async isOfferFavorited(offerId: number) {
    try {
      const params = {};
      const result: ISpreaderOffer = await this.request<ISpreaderOffer>('GET', `spreader/offer/${offerId}`
                                                                        , { params });
      if (result) {
        return result.favorite;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  async getSpreaderOffer(offerId: number) {
    let result: ISpreaderOffer;

    try {
      const params = {};
      result = await this.request<ISpreaderOffer>('GET', `spreader/offer/${offerId}`, { params });
    } catch (e) {}

    return result;
  }

  /**
   * Returns the offer that has the provided id
   *
   */
  getOfferById(offerId: number) {
    return this.request('GET', `offers/${offerId}`);
  }

  /**
   * Favorite/Unfavorite an offer
   */
  async favoriteOffer(offerId: number, isFavorited?: boolean): Promise<boolean> {
    const endpoint = `spreader/offer/${isFavorited ? 'un_fav' : 'fav'}/${offerId}`;

    await this.request('PUT', endpoint);

    return !isFavorited;
  }

  /**
   * Share history
   */
  async getOfferShares() {
    this.sharedHistory = await this.request<IOfferShare[]>('GET', 'spreader/share_history');

    return this.sharedHistory;
  }

  /**
   * Get offer share
   */
  async getOfferShare(shareDate: number, platform: SharePlatform) {
    if (!this.sharedHistory.length) {
      await this.getOfferShares();
    }

    return this.sharedHistory.filter(item => Number(item.shareDate) === Number(shareDate) && item.sharePlatform === platform)[0];
  }

  /**
   * Share an offer
   *
   * @param offer: any
   * @param shareType: 'facebook' | 'twitter' | 'instagram' | 'sms' | 'email'
   * @param recipients: Contacts with whom the offer will be shared
   */
  async shareOffer(offer: IOffer, shareType: ShareType, recipients?: Contact[]): Promise<IOfferShareResults> {

    const shareId = generateShareId();

    const shareUrl = await this.dynamicLinkService.createDynamicLink(offer, shareId, shareType);

    let greeting = 'Hi everyone';
    let message = this.createShareTextMessage(offer, offer.business, shareUrl);

    const sender = this.authService.currentUser;

    try {
      switch (shareType) {
        case 'facebook':
          // TODO: Once the Facebook plugin is corrected, add the await keyword to the following share service call
          this.shareService.shareViaFacebook(message, shareUrl);
          break;

        case 'twitter':
          await this.shareService.shareViaTwitter(message, shareUrl);
          break;

        case 'instagram':
          const image = await this.createOfferImage(offer.id);
          if (image) {
            await this.shareService.shareViaInstagram(message, image);
          }
          break;

        case 'sms':
          const phones = recipients.map(r => r.phoneNumber) as string[];
          greeting = recipients.length > 1 ? greeting : (recipients[0].nickname || recipients[0].displayName);
          message = this.createShareTextMessage(offer, offer.business, shareUrl, greeting);

          await this.shareService.shareViaSMS(phones, message);
          break;

        case 'email':
          const emails = recipients.map(r => r.email) as string[];
          greeting = recipients.length > 1 ? greeting : recipients[0].name.givenName;
          message = this.createShareHTMLMessage(offer, offer.business, shareUrl, greeting);

          await this.shareService.shareViaEmails({
            blindCopyRecipients: emails,
            subject: '',
            body: message,
            sentFromEmail: sender.userId,
            sentFromName: `${sender.firstName} ${sender.lastName}`
          });
          break;

        default:
          break;
      }

      console.log('*** Saving share to DB');
      // Save offer shares
      return await this.saveOfferShare({
            shareId: shareId,
            offerId: offer.id,
            spreaderId: sender.id,
          },
          recipients,
          shareType
      );
    } catch (e) {
      console.error(`*** Failed sharing offer: ${offer.id} via ${shareType}`, e);
      throw e;
    } finally {
      console.log('*** Exiting share service call');
    }
  }

  private async saveOfferShare(options: {
    shareId: string,
    offerId: number,
    shareMethod?: SharePlatform,
    spreaderId: number,
    recipients?: string
  }, recipients: Contact[], shareType: ShareType): Promise<IOfferShareResults> {

    if (shareType === 'email') {
      const emails = recipients.map(r => r.email) as string[];
      options.recipients = emails.join(',');
    }

    if (shareType === 'sms') {
      const phones = recipients.map(r => r.phoneNumber) as string[];
      options.recipients = phones.join(',');
    }

    options.shareMethod = shareOptions[shareType].platform;

    return this.request<IOfferShareResults>('POST', 'spreader/offers/shares', {
      body: JSON.stringify(options),
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
    });
  }

  /**
   * Generates an text offer share message
   *
   */
  private createShareTextMessage(offer: IOffer, business: IBusiness, shareUrl: string, greeting?: string) {
    if (!offer || !business) {
      throw new Error(`Business and offer must be selected for sharing`);
    }

    let message = greeting ? greeting + ', ' : '';

    if (!offer.loyalty) {
      message += `I think you'll love this deal from ${business.name}!`;
    } else {
      message += `Checkout the ${business.name} loyalty program; earn awesome rewards!`;
    }

    message += ` See details here ${shareUrl}`;

    // @TODO: replace maxLength with a constant
    if (message.length > Constants.MAX_SHARE_MESSAGE_SIZE) {
      message = `I think you'll love this karrot! See details here ${shareUrl}`;

      if (message.length > Constants.MAX_SHARE_MESSAGE_SIZE) {
        throw new Error(`Message length exceeds max of ${Constants.MAX_SHARE_MESSAGE_SIZE}`);
      }
    }

    return message;
  }

  /**
   * Generates an text offer share message
   *
   */
  private createShareHTMLMessage(offer: IOffer, business: IBusiness, shareUrl: string, greeting: string) {
    if (!offer || !business) {
      throw new Error(`Business and offer must be selected for sharing`);
    }

    let messageBody = '';

    if (!offer.loyalty) {
      messageBody += `I think you'll love this deal from <span style="font-weight: bold">${business.name}</span>!`;
    } else {
      messageBody += `Checkout the <span style="font-weight: bold">${business.name}</span> loyalty program; earn awesome rewards!`;
    }

    const aTag = `<a href="${shareUrl}" target="_offer">See the details in the Karrot app!</a>`;
    const message = `<html><div>${greeting},</div><div>${messageBody}</div><div>${offer.description}</div><div>${aTag}</div></html>`;

    return message;
  }

  private createOfferImage(offerId: number): Promise<string> {
    return this.request('GET', `offers/create_offer_image/${offerId}`);
  }

  /**
   * Validates that the shared offer is still good
   * @param offerId
   * @param shareId
   * @param receiverId
   */
  public validateOfferShare(offerId, shareId, receiverId): Promise<void> {
    const prms = {'offer-id': offerId, 'share-id': shareId, 'receiver-id': receiverId};
    return this.request('GET', 'spreader/offers/validate_share', { params: prms });
  }

  /**
   * Saves the offer share for the current user
   *
   * @param {Offer} offer
   * @param {string} shareId
   * @returns {Promise<Offer>}
   */
  saveSharedOffer(_offer: any, shareId: string): Promise<ISpreaderOffer> {
    return this.request<ISpreaderOffer>('PUT', `spreader/shared_offer/${shareId}`);
  }
}
