import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IntroService {

  readonly storageKey = 'did_intro';


  get didIntro(): boolean {
    return localStorage.getItem(this.storageKey) === 'true';
  }

  set didIntro(value: boolean) {
    localStorage.setItem(this.storageKey, String(value));
  }
}
