import { Component } from '@angular/core';
import { CommonModalPage } from 'src/app/shared/components/common.modal';
import {OfferService} from '../../services/offer.service';

@Component({
  selector: 'modal-redeem',
  templateUrl: './redeem.modal.html',
  styleUrls: ['./redeem.modal.scss'],
})
export class RedeemModal extends CommonModalPage {
  offerId: number;
  couponImage: string;

  constructor(private offerService: OfferService) {
    super();
  }

  async submitRequest(ticketId) {
    try {
      await this.offerService.submitRedeemRequest(this.offerId, ticketId);
      super.close(true);
    } catch (e) {
      super.close(e.error);
      console.log(`*** Error submitting redeem request ${JSON.stringify(e)} ***`);
    }
  }
}
