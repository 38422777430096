import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RedeemModal } from './redeem.modal';
import { QRCodeModule, RatingModule } from 'ionic4-kits';
import {PipesModule} from '../../shared/pipes/pipes.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        QRCodeModule,
        RatingModule,
        PipesModule
    ],
  declarations: [RedeemModal],
  entryComponents: [
    RedeemModal
  ],
  exports: [
    RedeemModal
  ]
})
export class RedeemModalModule {}
