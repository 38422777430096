// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serviceUrl: 'http://localhost:8080/karrot/res',
  imagesUrl: 'https://s3.us-east-2.amazonaws.com/karrot-images/test',
  mobileWebUrl: 'https://m-test.karrot.app/',
  firebaseAppConfig: {
    apiKey: 'AIzaSyDVdCBBsHCE0oE-ueTfHIl46Y-k5hXuSHU',
    authDomain: 'karrot-220816.firebaseapp.com',
    databaseURL: 'https://karrot-220816.firebaseio.com',
    projectId: 'karrot-220816',
    storageBucket: 'karrot-220816.appspot.com',
    messagingSenderId: '958091571665'
  },
  googleJSApiKey: 'AIzaSyCx7GKrTJ5DBzaqKidMfWTSN5VBb41kzS8',
  prodServiceUrl: 'https://karrot.iapps.cc/karrot/res',
  prodImagesUrl: 'https://s3.us-east-2.amazonaws.com/karrot-images/prod',
  prodMobileWebUrl: 'https://m.karrot.app/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
