import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../constants';

const maxAge = 3000;

@Injectable({
  providedIn: 'root'
})
export class CacheInterceptor implements HttpInterceptor {
  private cache = new Map<string, any>();

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method !== 'GET') {
      return next.handle(request);
    }

    // Only check the cache if the cached parameter exists
    const cacheEnabled = request.params.get(Constants.PARAM_NAME_CACHE_ENABLE);
    if (cacheEnabled) {
      request.params.delete(Constants.PARAM_NAME_CACHE_ENABLE);
    }

    return next.handle(request).pipe(
      map(event => {

        if (!cacheEnabled) {
          return event;
        }

        if (event instanceof HttpResponse) {
          const cached = this.cache.get(request.urlWithParams);

          if (!cached || Date.now() - cached.lastRead > maxAge) {
            this.cache.set(request.urlWithParams, {lastRead: Date.now(), response: event});
            return event;
          }

          return cached.response;
        }
      })
    );
  }
}
