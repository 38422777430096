import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FailedModal } from './failed.modal';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  declarations: [FailedModal],
  entryComponents: [
    FailedModal
  ],
  exports: [
    FailedModal
  ]
})
export class FailedModalModule {}
