import { ILocation } from '../domain';
import { ILatLng } from '@ionic-native/google-maps/ngx';

// tslint:disable: no-bitwise

const RADIUS = 6371;

export const sum = (a: number, b: number) => {
  return a + b;
};

/**
 * Degree to Radian
 */
export function toRadian(degree: number) {
  return degree * Math.PI / 180;
}

/**
 * Get distance from two positions
*/
export function distanceBetween(p1: ILocation, p2: ILocation) {
  const dLat = toRadian(p2.latitude - p1.latitude);
  const dLng = toRadian(p2.longitude - p1.longitude);

  const lat1 = toRadian(p1.latitude);
  const lat2 = toRadian(p2.latitude);

  const d = Math.pow(Math.sin(dLat / 2), 2) + Math.pow(Math.sin(dLng / 2), 2) * Math.cos(lat1) * Math.cos(lat2);

  return 2 * Math.atan2(Math.sqrt(d), Math.sqrt(1 - d)) * RADIUS;
}

/**
 * Miles to Meters
 */
export function milesToMeters(miles: number): number {
  return miles * 1609.34;
}

/**
 * 31 randomly generated integers between 0-9 combined to create a UUID
 *
 * @returns string
 */
export function generateShareId(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

/**
 * Deep compare objects.
 *
 * @see http://stackoverflow.com/a/1144249
 */
export function compare2Objects(obj1: Object | any, obj2: Object | any) {
  return JSON.stringify(Object.assign({}, obj1)) === JSON.stringify(Object.assign({}, obj2));
}

/**
 * Generate Google Maps URL
 */
export function googleMapsURL(addressLine1: string, city: string, state: string, postalCode: string) {
  return `https://www.google.com/maps/search/?api=1&query=${addressLine1},${city},${state},${postalCode}`;
}

/**
 * Generate Google Maps Direction URL
 */
export function googleMapsDirectionURL(startPoint: ILatLng): string {
  return `https://www.google.com/maps/dir/?api=1
                                        &destination=${startPoint.lat},${startPoint.lng}&travelMode=driving`;
}

/**
 * Parse query params from a url
 *
 * @param url string
 */
export function getParamsFromURL(url: string) {
  const pl     = /\+/g;  // Regex for replacing addition symbol with a space
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = (s: any) => decodeURIComponent(s.replace(pl, ' '));
  const query  = url.split('?').pop();

  let match: any;
  const urlParams: any = {};
  while (match = search.exec(query)) {
      urlParams[decode(match[1])] = decode(match[2]);
  }

  return urlParams;
}
