import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppInjector } from '../app.injector';
import { ConfigService } from '../../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class CommonApi<T = any> {
  httpClient: HttpClient;
  configService: ConfigService;
  hasDataConnection: boolean;

  constructor() {
    const injector = AppInjector.injector;
    this.httpClient = injector.get<HttpClient>(HttpClient);
    this.configService = injector.get<ConfigService>(ConfigService);
  }

  request<R = T>(method: 'GET' | 'PUT' | 'POST' | 'DELETE', endPoint: string, options?: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: any;
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: any;
    };
    responseType?: 'json';
    reportProgress?: boolean;
    withCredentials?: boolean;
  }): Promise<R> {
      const apiURL = this.configService.getServiceUrl();
      return this.httpClient.request<R>(method, `${apiURL}/${endPoint}`, options).toPromise();
  }
}
