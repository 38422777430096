import { Component } from '@angular/core';
import { CommonModalPage } from 'src/app/shared/components/common.modal';

@Component({
  selector: 'modal-success',
  templateUrl: './info.modal.html',
  styleUrls: ['./info.modal.scss'],
})
export class InfoModal extends CommonModalPage {
  title: string;
  message: string;
}
