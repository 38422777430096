import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { CheckoutModal } from './checkout/checkout.modal';
import { CommentModal } from './comment/comment.modal';
import { ConfirmModal } from './confirm/confirm.modal';
import { FailedModal } from './failed/failed.modal';
import { InfoModal } from './info/info.modal';
import { SuccessModal } from './success/success.modal';
import { CreateContactModal } from './create-contact/create-contact.modal';
import { RatingModal } from './rating/rating.modal';
import { ShareModal } from './share/share.modal';

import { IOffer, ShareType } from '../domain';
import {RedeemModal} from './redeem/redeem.modal';
import {ScanRedeemModal} from './scan-redeem/scan.redeem.modal';
import {CouponModal} from './coupon/coupon-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalsProvider {

  constructor(private modalCtrl: ModalController) { }

  // checkout modal
  async openCheckout(offer: IOffer): Promise<boolean> {
    return this.createModal(CheckoutModal, { offer }, 'modal-checkout');
  }

  // comment modal
  async openComment(): Promise<string> {
    return this.createModal(CommentModal, {}, 'modal-comment');
  }

  // confirm modal
  async openConfirm(message: string): Promise<boolean> {
    return this.createModal(ConfirmModal, { message }, 'modal-confirm');
  }

  // failure modal
  async openFailure(message: string, title = 'Failure'): Promise<void> {
    return this.createModal(FailedModal, { message, title }, 'modal-failed');
  }

  // information modal
  async openInfo(message: string, title = 'FYI') {
    return this.createModal(InfoModal, { title, message }, 'modal-info');
  }

  // success modal
  async openSuccess(message: string, title = 'Success') {
    return this.createModal(SuccessModal, { title, message }, 'modal-success');
  }

  // create contact modal
  async openCreateContact(shareType: 'email' | 'sms'): Promise<{firstName: string, lastName?: string, email?: string, mobile?: string}> {
    return this.createModal(CreateContactModal, { shareType }, 'modal-create-contact');
  }

  // rating modal
  async openRating(rate = 3, title = 'Rate this Visit'): Promise<number> {
    return this.createModal(
      RatingModal, { rate, title }, 'modal-rating');
  }

  // redeem modal
  async openRedeem(offerId: number): Promise<any> {
    return this.createModal(
        RedeemModal, { offerId }, 'modal-redeem');
  }

  // redeem modal
  async openScanRedeem(offerId: number, redeemerId: number, shareId: string): Promise<any> {
    return this.createModal(
        ScanRedeemModal, { offerId, redeemerId, shareId }, 'modal-redeem');
  }

  async openCoupon(offerId: number, couponImage: string): Promise<any> {
    return this.createModal(
        CouponModal, { offerId, couponImage }, 'modal-coupon');
  }

  // share modal
  async openShare(title: string): Promise<ShareType> {
    return this.createModal(ShareModal, { title }, 'modal-share');
  }

  private async createModal(component: any, componentProps: any, cssClass: string) {
    cssClass = `modal-common ${cssClass}`;
    const modal = await this.modalCtrl.create({
      component, componentProps, cssClass
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();

    return data;
  }
}
