import { Component, OnInit } from '@angular/core';
import { AppInjector } from '../app.injector';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-common-modal',
  template: '',
})
export class CommonModalPage implements OnInit {
  protected modalCtrl: ModalController;

  constructor() {
    const injector = AppInjector.injector;
    this.modalCtrl = injector.get<ModalController>(ModalController);
  }

  ngOnInit() {

  }

  close(data?: any) {
    this.modalCtrl.dismiss(data);
  }

}
