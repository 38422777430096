export class Constants {
  static FACEBOOK_ACCESS_SCOPE = ['public_profile', 'email'];

  static STORAGE_KEY_USER = 'current_user';
  static STORAGE_KEY_JWT = 'jwt_token';
  static STORAGE_KEY_LOCATION = 'current_location';   // device's location
  static STORAGE_KEY_MAP_LOCATION = 'map_location';   // location picked by a user in the map
  static STORAGE_KEY_PREFERENCE = 'current_config';
  static STORAGE_KEY_PUSH_TOKEN = 'push_devie_token';
  static STORAGE_KEY_PATH_LAST_SEEN = 'page_last_visited';

  static STORAGE_KEY_OFFERS_CATEGORIZED = 'categorized_offers';

  static OFFERS_CACHE_TIME = 4 * 3600 * 1000; // 4hrs,

  static OFFERS_LOAD_DISTANCE = 1.0;

  static MAX_MAP_SEARCH_RADIUS = 150;

  static EVENT_LOCATION_CHANGED = 'topic_location_changed';
  static EVENT_CONFIG_CHANGED = 'topic_configuration_changed';

  public static DEEP_LINK_ACTION = 'action';
  public static DEEP_LINK_ACTION_GOTO_OFFER = 'go-to-offer';
  public static DEEP_LINK_ACTION_REGISTER = 'register';
  public static SHARED_OFFER_SPREADER_ID_KEY = 'sid';
  public static SHARED_OFFER_ID_KEY = 'oid';

  public static SHARED_BUSINESS_ID_KEY = 'bid';

  public static DYNAMIC_LINK_URL = 'https://get.karrot.app';
  public static MAX_SHARE_MESSAGE_SIZE = 256;

  static PARAM_NAME_CACHE_ENABLE = 'cache-disabled';

  public static ENVIRONMENT_MODE = 'environment';

  /**
   * Service errror constants
   */
  public static SE_REDEMPTION_PENDING = 'redemption_pending';

  public static NETWORK_ERROR_MESSAGE = 'No internet connection!';
  public static NETWORK_ERROR_NAME = 'no-internet-connection';
}
