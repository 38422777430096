import { Injectable } from '@angular/core';
import { HttpInterceptor as BaseInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Network } from '@ionic-native/network/ngx';
import { Observable, EMPTY } from 'rxjs';
// import { Constants } from '../constants';


@Injectable({
  providedIn: 'root'
})
export class HttpInterceptor implements BaseInterceptor {

  constructor(private network: Network) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.network.type === 'none') {
      console.log('=================================');
      console.log('=========== No network ==========');
      console.log('=================================');

      // const err = new Error(Constants.NETWORK_ERROR_MESSAGE);
      // err.name = Constants.NETWORK_ERROR_NAME;
      // throw err;
      return EMPTY;
    }

    return next.handle(request);
  }
}
