import { Component } from '@angular/core';
import { CommonModalPage } from 'src/app/shared/components/common.modal';
import {OfferService} from '../../services/offer.service';

@Component({
  selector: 'modal-coupon',
  templateUrl: './coupon-modal.component.html',
  styleUrls: ['./coupon-modal.component.scss'],
})
export class CouponModal extends CommonModalPage {
  offerId: number;
  couponImage: string;

  constructor(private offerService: OfferService) {
    super();
  }

  async redeemCoupon() {
    try {
      await this.offerService.redeemCoupon(this.offerId);
      super.close(true);
    } catch (e) {
      super.close(e.error);
      console.log(`*** Error submitting coupon redemption ${JSON.stringify(e)} ***`);
    }
  }
}
