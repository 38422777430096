import { Component } from '@angular/core';
import { CommonModalPage } from 'src/app/shared/components/common.modal';

@Component({
  selector: 'modal-success',
  templateUrl: './success.modal.html',
  styleUrls: ['./success.modal.scss'],
})
export class SuccessModal extends CommonModalPage {
  message: string;
  title: string;
}

