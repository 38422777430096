import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { AppInjector } from '../app.injector';


@Pipe({
  name: 's3Image'
})
export class S3ImagePipe implements PipeTransform {

  private configService: ConfigService;

  constructor() {
    const injector = AppInjector.injector;
    this.configService = injector.get<ConfigService>(ConfigService);
  }

  transform(imageId: string): any {
    const imageUrl = this.configService.createImageUrl(imageId);
    return imageId ? `${imageUrl}` : 'assets/imgs/karrot-intro-1.svg';
  }

}
