import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { InfoModal } from './info.modal';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  declarations: [InfoModal],
  entryComponents: [
    InfoModal
  ],
  exports: [
    InfoModal
  ]
})
export class InfoModalModule {}
