import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CommentModal } from './comment.modal';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  declarations: [
    CommentModal
  ],
  entryComponents: [
    CommentModal
  ],
  exports: [
    CommentModal
  ]
})
export class CommentModalModule {}
