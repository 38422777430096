import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  constructor() {
  }

  transform(phone: string): any {
    // Strip everything except the numbers
    let formattedPhone = phone.replace(/\D+/g, '');

    console.log(`*** Normalized phone ${formattedPhone}`);

    if (formattedPhone.length === 12) {
      formattedPhone = //
        `+${formattedPhone.substr(0, 2)}-${formattedPhone.substr(2, 3)}-${formattedPhone.substr(5, 3)}-${formattedPhone.substr(8, 4)}`;
    } else if (formattedPhone.length === 11) {
      formattedPhone = //
        `${formattedPhone.substr(0, 1)}-${formattedPhone.substr(1, 3)}-${formattedPhone.substr(4, 3)}-${formattedPhone.substr(7, 4)}`;
    } else if (formattedPhone.length === 10) {
      formattedPhone = `1-${formattedPhone.substr(0, 3)}-${formattedPhone.substr(3, 3)}-${formattedPhone.substr(6, 4)}`;
    } else if (formattedPhone.length === 7) {
      formattedPhone = `${formattedPhone.substr(0, 3)}-${formattedPhone.substr(3, 4)}`;
    } else {
      formattedPhone = phone;
    }

    return formattedPhone;
  }

}
