import { Injector } from '@angular/core';

export class AppInjector {
    private static _injector: Injector;

    public static get injector(): Injector {
        return AppInjector._injector;
    }

    public static set injector(injector: Injector) {
        AppInjector._injector = injector;
    }
}
