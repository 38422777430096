import { Injectable } from '@angular/core';
import { IOffer, ShareType } from '../domain';
import { ConfigService } from './config.service';

/**
 * Service for construction of sharable dynamic links
 */
@Injectable({
  providedIn: 'root'
})
export class DynamicLinkService {

  constructor(private configService: ConfigService) {
  }

  /**
   * This service method generates a short Karrot dynamic link URL for the offer that can be shared
   *
   * @param offer The offer that is to be shared
   * @param shareId The unique ID of the share transaction.
   * @param shareType The platform the link will be shared on
   */
  public async createDynamicLink(offer: IOffer, shareId: String, shareType: ShareType): Promise<string> {

    // New mobileweb URL is https://m.karrot.app/offers/156/detail/7eeb3052-323c-4e6b-9a08-c73f3cb6df4b
    const base_url = this.configService.getMobileWebUrl();
    const path = 'offers/' + offer.id + '/detail/' + shareId;

    const url = base_url + path;

    console.log('createDynamicLink ' + shareType + ' ' + url);

    return url;
  }

}
