import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';

import { CommonApi } from '../shared/services/common.api';
import { IUser, ILocation } from '../domain';
import { Constants } from '../shared/constants';
import { FirebaseService } from './firebase.service';
import { LocationService } from './location.service';
import { PreferenceService } from './preference.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends CommonApi<IUser> {

  get currentUser() {
    return JSON.parse(localStorage.getItem(Constants.STORAGE_KEY_USER));
  }

  set currentUser(user: IUser) {
    localStorage.setItem(Constants.STORAGE_KEY_USER, JSON.stringify(user));

    if (user && user.password) {
      localStorage.setItem(Constants.STORAGE_KEY_JWT, user.password);
    }
  }

  get pathLastSeen() {
    return localStorage.getItem(Constants.STORAGE_KEY_PATH_LAST_SEEN);
  }

  set pathLastSeen(path: string) {
    localStorage.setItem(Constants.STORAGE_KEY_PATH_LAST_SEEN, path);
  }

  constructor(
    private afAuth: AngularFireAuth,
    private facebook: Facebook,
    private firebaseService: FirebaseService,
    private locationService: LocationService,
    private preferenceService: PreferenceService

  ) {
    super();
  }

  isLoggedIn() {
    return !!this.currentUser;
  }

  clearPathLastSeen() {
    localStorage.removeItem(Constants.STORAGE_KEY_PATH_LAST_SEEN);
  }

  // login with credential
  loginWithEmail(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then(async res => {
        const idToken = await res.user.getIdToken(false);

        const user: IUser = await this.request('POST', `security/spreader/auth`, {
          headers: new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/x-www-form-urlencoded' }),
          body: `uid=${idToken}`
        });

        this.currentUser = user;

        // update user preference
        await this.updateUserPref();

        return user;
      });
  }

  // registration
  signupWithEmail(email: string, password: string, firstName: string, lastName: string = '') {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then(_ => {
        return this.createUser(email, firstName, lastName);
      }, error => {
        throw error;
      });
  }

  // connect with facebook
  connectWithFacebook(): Promise<IUser | any> {
    return this.facebook.login(Constants.FACEBOOK_ACCESS_SCOPE)
      .then((fbLoginRes: FacebookLoginResponse) => {
        console.log(`*** Facebook login status ${JSON.stringify(fbLoginRes)}`);
        if (fbLoginRes.status !== 'connected') {
          // handle error
          return;
        }

        // fetch user data with facebook api
        return this.facebook.api('/me?fields=id,first_name,last_name,email', Constants.FACEBOOK_ACCESS_SCOPE)
          .then(async (fbApiRes: any) => {
            const { email, first_name, last_name } = fbApiRes;

            const user = await this.request('GET', `security/login_fb/${fbLoginRes.authResponse.userID}`, {
              params: {
                email: email,
                firstName: first_name,
                lastName: last_name
              }
            });
            user.facebookAccessToken = fbLoginRes.authResponse.accessToken;
            this.currentUser = user;

            if (user.password) {
              // update user preference
              await this.updateUserPref();
            }

            return user;
          });
      });
  }


  // reset password
  resetPassword(email: string) {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }

  // signout
  logout() {
    localStorage.removeItem(Constants.STORAGE_KEY_USER);
    localStorage.removeItem(Constants.STORAGE_KEY_PATH_LAST_SEEN);
    localStorage.removeItem(Constants.STORAGE_KEY_JWT);
    this.currentUser = null;
    return this.afAuth.auth.signOut();
  }

  // create account with a given information
  async createUser(email: string, firstName: string, lastName: string) {
    const user = await this.request('POST', `security/spreader/register`, {
      body: {
        email: email,
        firstName: firstName,
        lastName: lastName
      }
    });

    this.currentUser = user;

    return user;
  }

  private async updateUserPref() {

    // save device Token
    try {
      const token = await this.firebaseService.getDeviceToken();
      if (token) {
        try {
          await this.saveDeviceToken(token, this.locationService.currentLocation);
        } catch (e) {
          console.log(`*** Device token save error: ${JSON.stringify(e)}`);
        }
      }
    } catch (e) {
      console.error(`*** Unable to get and save device token ${JSON.stringify(e)} ***`);
    }

    // save user preference
    if (!localStorage.getItem(Constants.STORAGE_KEY_PREFERENCE)) {

      if (this.currentUser.preferencesJSON) {

        await this.preferenceService.updateConfig(JSON.parse(this.currentUser.preferencesJSON));
      }
    }

  }

  /**
   * Sends the devices push token along with the geo location to be stored in the DB
   */
  private async saveDeviceToken(token: string, location?: Partial<ILocation>) {
    if (location) {
      location = { latitude: location.latitude, longitude: location.longitude, altitude: location.altitude, accuracy: location.accuracy };
    } else {
      location = { latitude: 0.0, longitude: 0.0, altitude: 0.0, accuracy: 0.0 };
    }
    const userId = this.currentUser.id;

    return this.request<void>('POST', 'profile/save_push_token', {
      body: { token: token, geoPosition: location, spreaderId: userId }
    }
    );
  }

}
