import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ShareModal } from './share.modal';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  declarations: [ShareModal],
  entryComponents: [
    ShareModal
  ],
  exports: [
    ShareModal
  ]
})
export class ShareModalModule {}
