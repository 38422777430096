import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SuccessModal } from './success.modal';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  declarations: [SuccessModal],
  entryComponents: [
    SuccessModal
  ],
  exports: [
    SuccessModal
  ]
})
export class SuccessModalModule {}
