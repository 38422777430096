import { Component, OnInit } from '@angular/core';
import { CommonModalPage } from 'src/app/shared/components/common.modal';
import { shareOptions, ShareType } from 'src/app/domain/offer-share';


@Component({
  selector: 'modal-share',
  templateUrl: './share.modal.html',
  styleUrls: ['./share.modal.scss'],
})
export class ShareModal extends CommonModalPage implements OnInit {
  title: string;
  shareOptions = shareOptions;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  // close modal with a selected type
  onSelect(type: ShareType) {
    this.close(type);
  }

}
