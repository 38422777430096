import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { ModalsProvider } from './modals.provider';

import { CheckoutModalModule } from './checkout/checkout.module';
import { CommentModalModule } from './comment/comment.module';
import { ConfirmModalModule } from './confirm/confirm.module';
import { CreateContactModalModule } from './create-contact/create-contact.module';
import { FailedModalModule } from './failed/failed.module';
import { InfoModalModule } from './info/info.modal.module';
import { RatingModalModule } from './rating/rating.module';
import { ShareModalModule } from './share/share.module';
import { SuccessModalModule } from './success/success.module';
import { RedeemModalModule } from './redeem/redeem.module';
import {ScanRedeemModalModule} from './scan-redeem/scan.redeem.module';
import {CouponModalModule} from './coupon/coupon.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    IonicModule,
    CheckoutModalModule,
    CommentModalModule,
    ConfirmModalModule,
    CreateContactModalModule,
    FailedModalModule,
    InfoModalModule,
    RatingModalModule,
    ShareModalModule,
    SuccessModalModule,
    RedeemModalModule,
      ScanRedeemModalModule,
      CouponModalModule
  ],
  providers: [
    ModalsProvider
  ]

})
export class ModalsModule { }
