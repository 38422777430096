import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';
import { Constants } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private environmentService: EnvironmentService
  ) {
    let environment_mode: any = this.environmentService.getEnvironmentMode();

    if (!environment_mode) {
      console.log('*** Environment mode not set, using environment file ***');
      environment_mode = environment.production ? 'production' : 'development';
      this.environmentService.setEnvironmentMode(environment_mode);
    }
  }

  public isProdMode() {
    return this.environmentService.isProdMode();
  }

  public createImageUrl(imageId: string): string {
    const environment_mode: any = this.environmentService.getEnvironmentMode();

    if (environment_mode === 'development') {
      return environment.imagesUrl + '/' + imageId;
    } else {
      return environment.prodImagesUrl + '/' + imageId;
    }
  }

  getServiceUrl() {
    const environment_mode: any = this.environmentService.getEnvironmentMode();

    if (environment_mode === 'development') {
      return environment.serviceUrl;
    } else {
      return environment.prodServiceUrl;
    }
  }


  getMobileWebUrl() {
    const environment_mode: any = this.environmentService.getEnvironmentMode();

    if (environment_mode === 'development') {
      return environment.mobileWebUrl;
    } else {
      return environment.prodMobileWebUrl;
    }
  }

  getHttpURL(url) {
    return this.getServiceUrl() + url;
  }

  appendHeaderWithToken() {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem(Constants.STORAGE_KEY_JWT)
    };
  }
}
