import { Injectable, ErrorHandler as BaseErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotifyService } from 'ionic4-kits';
import {ConfigService} from '../../services/config.service';
import {Constants} from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandler extends BaseErrorHandler {

  constructor(private notifyService: NotifyService, private configService: ConfigService) {
    super();
  }

  handleError(error: Error | HttpErrorResponse | any) {
    error = error.rejection || error;

    // handle http errors
    if (error instanceof HttpErrorResponse || error === 'cordova_not_available') {
      console.log(error + ': ' + error.message);

    } else {
      console.log(error);

      /**
       * Do not show the notification if in prod mode
       */
      if (!this.configService.isProdMode()) {
        this.notifyService.showNotify(error.message || JSON.stringify(error), 'error').catch();
      } else if (error.name === Constants.NETWORK_ERROR_NAME) {
        this.notifyService.showNotify(error.message, 'error').catch();
      }
    }
  }
}
