import { Injectable } from '@angular/core';
import { Constants } from '../shared/constants';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() {
    let environment_mode: any = this.getEnvironmentMode();

    if (!environment_mode) {
      console.log('*** Environment mode not set, using environment file ***');
      environment_mode = environment.production ? 'production' : 'development';
      this.setEnvironmentMode(environment_mode);
    }
  }

  setEnvironmentMode(mode) {
    localStorage.setItem(Constants.ENVIRONMENT_MODE, mode);
  }

  getEnvironmentMode() {
    return localStorage.getItem(Constants.ENVIRONMENT_MODE);
  }

  public isProdMode() {
    return this.getEnvironmentMode() === 'production';
  }
}
