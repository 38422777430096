import { Component } from '@angular/core';
import { CommonModalPage } from 'src/app/shared/components/common.modal';

@Component({
  selector: 'modal-rating',
  templateUrl: './rating.modal.html',
  styleUrls: ['./rating.modal.scss'],
})
export class RatingModal extends CommonModalPage {
  title: string;
  rate: number;
}
