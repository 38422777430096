import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { CommonApi } from '../shared/services/common.api';
import { Facebook } from '@ionic-native/facebook/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { SMS, SmsOptions } from '@ionic-native/sms/ngx';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { Platform } from '@ionic/angular';
import { ShareType } from '../domain';


@Injectable({
  providedIn: 'root'
})
export class ShareService extends CommonApi {

  constructor(
    private facebook: Facebook,
    private socialSharing: SocialSharing,
    private sms: SMS,
    private clipboard: Clipboard,
    private platform: Platform,
    private appAvailability: AppAvailability
  ) {
    super();
  }

  shareVia() {

  }

  async shareViaFacebook(message: string, url: string) {
    await this.clipboard.copy(message);

    console.log(`*** Facebook share URL: ${url} ***`);

    let scheme: string;
    if (this.platform.is('ios')) {
      scheme = 'fb://';
    } else if (this.platform.is('android')) {
      scheme = 'com.facebook.katana';
    } else {
      return;
    }

    let isFBInstalled: boolean;
    try {
      isFBInstalled = await this.appAvailability.check(scheme);
    } catch (e) {
      console.log('*** FacebookApp is not installed. ***', e);
      isFBInstalled = false;
    }

    const dialogOptions = {
      method: 'share',
      href: url,
      caption: null,
      description: null,
      picture: null
    };

    if (isFBInstalled) {
      dialogOptions['share_native'] = true;
    } else {
      dialogOptions['share_feedweb'] = true;
    }

    return this.facebook.showDialog(dialogOptions);
  }

  public async canShareVia(shareType: ShareType): Promise<any> {
    if ('twitter' === shareType) {
      return await this.canShareViaTwitter();
    } else if ('instagram' === shareType) {
      return await this.canShareViaInstagram();
    } else if ('facebook' === shareType || 'sms' === shareType || 'email' === shareType) {
      return new Promise<any>((resolve) => { resolve(true); });
    } else {
      return new Promise<any>((resolve) => { resolve(false); });
    }
  }

  async canShareViaTwitter() {
    let result  = false;
    try {
      await this.socialSharing.canShareVia('com.apple.social.twitter');
      result = true;
    } catch (e) {
      console.error('Error checking for twitter share: ', e);
    }

    return result;
  }

  async shareViaTwitter(message: string, url: string) {
    if (this.canShareViaTwitter()) {
      try {
        await this.socialSharing.shareViaTwitter(message, null, url);
      } catch (e) {
        console.log('*** Sharing exception: ', e);
        if (e === 'cancelled') {
          throw e;
        }
      }
    }
  }

  async canShareViaInstagram() {
    let result  = false;
    try {
      await this.socialSharing.canShareVia('instagram');
      result = true;
    } catch (e) {
      console.error('Error checking for twitter share: ', e);
    }

    return result;
  }

  async shareViaInstagram(message: string, imageURI: any) {
    if (this.canShareViaInstagram()) {
      try {
        await this.socialSharing.shareViaInstagram(message, imageURI);
      } catch (e) {
        console.log('*** Sharing exception ***', e);
        if (e === 'cancelled') {
          throw e;
        }
      }
    }
  }

  async shareViaEmails(options: {
    blindCopyRecipients: string[],
    subject: string,
    body: string,
    sentFromEmail: string,
    sentFromName: string
  }) {
    options.subject = `${options.sentFromName} thinks you'll love this!`;

    return this.request('POST', 'spreader/send_email', {
      body: JSON.stringify(options),
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
    });

  }

  async shareViaSMS(phones: string | string[], message: string, options: SmsOptions = {replaceLineBreaks: true, android: null}) {
    options = options || {};
    if (this.platform.is('android')) {
      options.android = { intent: 'INTENT' };
    }

    return this.sms.send(phones, message, options);
  }
}
