import { Injectable } from '@angular/core';
import { Firebase } from '@ionic-native/firebase/ngx';
import { Platform } from '@ionic/angular';

import { Constants } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private firebase: Firebase,
    private platform: Platform
  ) { }

  /**
   * Firebase Log Event
   */
  async logEvent(pageName: string) {
    if (this.platform.is('cordova')) {
      await this.firebase.logEvent('page_view', { page: pageName });
      await this.firebase.setScreenName(pageName);
    }
  }

  async logDeepLinkEvent(deepLink: string) {
    if (this.platform.is('cordova')) {
      await this.firebase.logEvent('deep_link', { link: deepLink });
    }
  }
  /**
   * Get FCM token
   */
  async getDeviceToken() {
    let deviceToken = sessionStorage.getItem(Constants.STORAGE_KEY_PUSH_TOKEN);

    if (this.platform.is('cordova')) {

      if (!deviceToken) {
        deviceToken = await this.firebase.getToken();

        if (this.platform.is('ios')) {
          await this.firebase.grantPermission();
        }
        sessionStorage.setItem(Constants.STORAGE_KEY_PUSH_TOKEN, deviceToken);
      }
    }

    return deviceToken;
  }

  /**
   * Listen to incoming FCM messages
   */
  listenToNotifications() {
    return this.firebase.onNotificationOpen();
  }
}
