import { Component } from '@angular/core';
import { CommonModalPage } from 'src/app/shared/components/common.modal';

@Component({
  selector: 'modal-checkout',
  templateUrl: './checkout.modal.html',
  styleUrls: ['./checkout.modal.scss'],
})
export class CheckoutModal extends CommonModalPage {
  offer: any;
}
