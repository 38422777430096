import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class FeaturePrefsService {

  constructor(
    private platform: Platform,
  ) { }

  public isLimitedMobileView(): boolean {
    return !this.platform.is('cordova');
  }
}
