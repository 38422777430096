import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonApi } from '../shared/services/common.api';

import { IConfig, IConfigUpdateEvent } from '../domain';
import { Constants } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService extends CommonApi<IConfig> {
  configChanged: Subject<IConfigUpdateEvent> = new Subject();
  defaultConfig: IConfig = {
    searchRadius: 20,
    locUpdateFreq: 2,
    pushSource: 'favorites'
  };

  constructor() {
    super();
  }

  getConfig(): IConfig {
    return JSON.parse(localStorage.getItem(Constants.STORAGE_KEY_PREFERENCE)) || this.defaultConfig;
  }

  async updateConfig(config: IConfig) {
    const prevConfig = this.getConfig();

    console.log(`*** Updating config: ${JSON.stringify(config)}`);

    await this.request('PUT', 'spreader/profile/save_prefs', {body: config});
    localStorage.setItem(Constants.STORAGE_KEY_PREFERENCE, JSON.stringify(config));

    this.publishChangedEvent(config, prevConfig);
    return;
  }

  resetCache() {
    localStorage.removeItem(Constants.STORAGE_KEY_PREFERENCE);
  }

  publishChangedEvent(currentConfig: IConfig, prevConfig: IConfig) {
    if (!this.isEqual(currentConfig, prevConfig)) {
      const changeEvent: IConfigUpdateEvent = { currentConfig: currentConfig, previousConfig: prevConfig };
      console.log(`*** Publishing change event: ${JSON.stringify(changeEvent)}`);
      this.configChanged.next(changeEvent);
    }
  }

  private isEqual(c1: IConfig, c2: IConfig): boolean {
    return c1.searchRadius === c2.searchRadius && c1.locUpdateFreq === c2.locUpdateFreq && c1.pushSource === c2.pushSource;
  }
}
