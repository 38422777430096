import { Component } from '@angular/core';
import { CommonModalPage } from 'src/app/shared/components/common.modal';

@Component({
  selector: 'modal-confirm',
  templateUrl: './confirm.modal.html',
  styleUrls: ['./confirm.modal.scss'],
})
export class ConfirmModal extends CommonModalPage {
  message: string;
}
