import { Component } from '@angular/core';
import { CommonModalPage } from 'src/app/shared/components/common.modal';

@Component({
  selector: 'modal-failed',
  templateUrl: './failed.modal.html',
  styleUrls: ['./failed.modal.scss'],
})
export class FailedModal extends CommonModalPage {
  message: string;
  title: string;
}
