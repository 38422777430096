import { NgModule, Injector, ErrorHandler as BaseErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { BranchIo } from '@ionic-native/branch-io/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { Contacts } from '@ionic-native/contacts/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { Firebase } from '@ionic-native/firebase/ngx';
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { GoogleMaps } from '@ionic-native/google-maps/ngx';
import { SMS } from '@ionic-native/sms/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { environment } from 'src/environments/environment';
import { AppInjector } from './shared/app.injector';
import { ErrorHandler } from './shared/handlers/error.handler';
import { CacheInterceptor } from './shared/interceptors/cache.interceptor';
import { HttpInterceptor } from './shared/interceptors/http.interceptor';

import { Constants } from './shared/constants';

import { Ionic4KitsModule } from 'ionic4-kits';
import { JwtModule } from '@auth0/angular-jwt';
import { ModalsModule } from './modals/modals.module';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

// use for Geocoding, because of cordova-plugin-googlemaps's Geocoding fails in some cases...
import { AgmCoreModule } from '@agm/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Network } from '@ionic-native/network/ngx';

export function jwtTokenGetter() {
  return localStorage.getItem(Constants.STORAGE_KEY_JWT);
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios'
    }),
    AppRoutingModule,
    Ionic4KitsModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseAppConfig),
    AngularFireAuthModule,
    HttpClientModule,
    ModalsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        whitelistedDomains: ['karrot.iapps.cc', 'karrot-test.iapps.cc', 'iapps.servehttp.com', 'localhost:8080', 'localhost:4200'],
        blacklistedRoutes: ['karrot-test.iapps.cc/karrot/res/security/spreader/auth', 'karrot.iapps.cc/karrot/res/security/spreader/auth']
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleJSApiKey
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AppAvailability,
    BranchIo,
    Clipboard,
    Contacts,
    Diagnostic,
    EmailComposer,
    Facebook,
    Firebase,
    FirebaseDynamicLinks,
    InAppBrowser,
    Geolocation,
    GoogleMaps,
    SMS,
    InAppBrowser,
    SocialSharing,
      Network,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: BaseErrorHandler, useClass: ErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

  constructor(injector: Injector) {
    AppInjector.injector = injector;
  }
}
