import { IOffer } from './offer';

export enum SharePlatform {
  Facebook = 1,
  Twitter,
  Instagram,
  SMS,
  Email
}

export interface ShareOption {
  icon: string;
  name: string;
  platform: SharePlatform;
}

export const shareOptions = {
  'facebook': {
    icon: 'facebook',
    name: 'Facebook',
    platform: SharePlatform.Facebook
  },
  'twitter': {
    icon: 'twitter',
    name: 'Twitter',
    platform: SharePlatform.Twitter
  },
  'instagram': {
    icon: 'instagram',
    name: 'Instagram',
    platform: SharePlatform.Instagram
  },
  'sms': {
    icon: 'sms',
    name: 'SMS',
    platform: SharePlatform.SMS
  },
  'email': {
    icon: 'email',
    name: 'Email',
    platform: SharePlatform.Email
  },
};

export type ShareType = keyof typeof shareOptions;

export interface IOfferShare {
    offer: IOffer;
    recipients: string;
    shareDate: number;
    sharePlatform: SharePlatform;
}
