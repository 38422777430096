import {Injectable} from '@angular/core';
import {CommonApi} from '../shared/services/common.api';
import {LocationService} from './location.service';
import {IReview} from '../domain';
import {HttpHeaders} from '@angular/common/http';
import {ILocation} from '../domain';
import {IBusiness} from '../domain';
import {IBusinessPoint} from '../domain';
import {PreferenceService} from './preference.service';
import {AuthService} from './auth.service';

import {IBusinessLocation} from '../domain/business-location';
import {IBusinessReviewSearchResult} from '../domain';

@Injectable({
  providedIn: 'root'
})
export class BusinessService extends CommonApi<IBusiness> {
  private favoritedBusinesses: IBusiness[] = [];

  constructor(private locationService: LocationService
    , private preferenceService: PreferenceService
    , private authService: AuthService) {
    super();
  }

  /**
   * Get business by ID
   */
  async getBusinessById(businessId: number, checkFavorite?: boolean) {
    const business = await this.request('GET', `profile/business/${businessId}`);

    if (checkFavorite) {
      business.favorited = await this.isBusinessFavorited(business.id);
    }

    return business;
  }

  /**
   * Get businesses
   */
  async getBusinesses(filter: 'favorited' | 'nearby', location?: ILocation, distance?: number) {
    location = location || this.locationService.currentLocation;

    distance = distance || (await this.preferenceService.getConfig()).searchRadius;

    let endpoint: string;

    if (this.authService.isLoggedIn()) {
      switch (filter) {
        case 'favorited':
          endpoint = 'spreader/business/favorites';
          break;
        case 'nearby':
          endpoint = 'spreader/business/nearby';
          break;
        default:
          break;
      }
    } else {
      endpoint = 'spreader/business/nearby';
    }


    const businesses = await this.request<IBusiness[]>('GET', endpoint, {
      params: { ...location, ...{ distance: distance } }
    });

    if (filter === 'favorited') {
      this.favoritedBusinesses = businesses;
    }

    return businesses;

  }

  /**
   * Business Locations
   *
   * @param businessId: number
   */
  getBusinessLocations(businessId: number) {
    return this.request<IBusinessLocation[]>('GET', `profile/all_locations/${businessId}`);
  }

  /**
   * Business Reviews
   *
   * @param businessId: number
   */
  getBusinessReviews(businessId: number, _count?: number) {
    return this.request<IBusinessReviewSearchResult>('GET', `profile/recent_reviews/${businessId}`);
  }

  /**
   * All business Points
   *
   */
  getAllBusinessPoints() {
    return this.request<IBusinessPoint[]>('GET', 'spreader/business_points');
  }

  /**
   * Business Points
   *
   */
  getBusinessPoints(businessId: number): Promise<IBusinessPoint> {
    const params = {};
    return this.request<IBusinessPoint>('GET', `spreader/business_points/${businessId}`, { params });
  }

  /**
   * Favorite/Unfavorite a business
   *
   */
  async favoriteBusiness(business: any): Promise<boolean> {
    const endpoint = `spreader/business/${business.favorited ? 'un_fav' : 'fav'}/${business.id}`;

    if (business.favorited) {
      await this.request('DELETE', endpoint, {
        headers: new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' })
      });

      // Uncache a favorited businesses
      if (business.favorited && this.favoritedBusinesses.length) {
        const idx = this.favoritedBusinesses.findIndex(b => Number(b.id) === Number(business.id));
        this.favoritedBusinesses.splice(idx, 1);
      }
    }

    if (!business.favorited) {
      await this.request('PUT', endpoint, {
        headers: new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' })
      });

      // Cache a favorited businesses
      if (this.favoritedBusinesses.length) {
        this.favoritedBusinesses.push(business);
      }
    }

    return !business.favorited;
  }

  /**
   * Check to see if this user was routed here from a shared registration link,
   * if so load the business and add it to their favorites
   */
  async handleBusinessFavorite(bid: number) {
    if (bid) {
      try {
        const business: IBusiness = await this.getBusinessById(bid, false);
        if (business) {
          business.favorite = false;
          await this.favoriteBusiness(business);
        }
      } catch (e) {
        console.log(`Error favoriting business ${JSON.stringify(e)}`);
      } finally {
      }
    }
  }

  /**
   * Saves the review to the Karrot DB
   *
   */

  submitReview(review: IReview) {
    const { latitude, longitude } = this.locationService.currentLocation;
    review.locationLat = latitude;
    review.locationLon = longitude;

    return this.request('POST', 'spreader/business/review', {
      headers: new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' }),
      body: JSON.stringify(review)
    });
  }


  /**
   * Check a business is favorited
   *
   * @param businessId
   */
  async isBusinessFavorited(businessId: number): Promise<boolean> {
    if (!this.favoritedBusinesses) {
      this.favoritedBusinesses = await this.getBusinesses('favorited') || [];
    }

    return this.favoritedBusinesses.filter(business => Number(business.id) === Number(businessId)).length > 0;
  }
}
