import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{
    path: '',
    loadChildren: './pages/tabs/tabs.module#TabsPageModule'
  }, {
    path: 'auth',
    loadChildren: './pages/auth/auth.module#AuthPageModule'
  }, {
    path: 'intro',
    loadChildren: './pages/walkthrough/walkthrough.module#WalkthroughPageModule'
  }, {
    path: 'offers',
    loadChildren: './pages/offers/offers.route.module#OffersRouteModule'
  }, {
    path: 'businesses',
    loadChildren: './pages/businesses/businesses.route.module#BusinessesRouteModule'
  }, {
    path: 'rewards',
    loadChildren: './pages/rewards/rewards.route.module#RewardsRouteModule'
  }, {
    path: 'settings',
    loadChildren: './pages/settings/settings.route.module#SettingsRouteModule'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
