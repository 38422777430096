import { Component, OnDestroy } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { IntroService } from './services/intro.service';
import { LocationService } from './services/location.service';
import { FirebaseService } from './services/firebase.service';
import { FeaturePrefsService } from './services/feature-prefs.service';
import { AuthService } from './services/auth.service';
import { Constants } from './shared/constants';
import { getParamsFromURL } from './utils';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalsProvider } from './modals/modals.provider';
import { BusinessService } from './services/business.service';
import { Network } from '@ionic-native/network/ngx';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnDestroy {
  loading: HTMLIonLoadingElement;
  private untilFn: Subject<any> = new Subject();

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navCtrl: NavController,
    private introService: IntroService,
    private locationService: LocationService,
    private firebaseService: FirebaseService,
    private authService: AuthService,
    private modalsProvider: ModalsProvider,
    private businessService: BusinessService,
    private network: Network,
    private featurePrefsService: FeaturePrefsService) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      if (this.platform.is('cordova')) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();

        this.initPush();

        /**
         * HACK: Delay the initial Branch deep linking initialization until the
         * home screen is queued to the navigator.
         */
        setTimeout(() => {
          this.platform.resume.subscribe(() => {
            this.branchInit();
          });

          this.branchInit();
        }, 1000);

        // Watch for position changes
        this.locationService.watchPosition(this.untilFn);

        // Watch network status
        this.network.onConnect()
                    .pipe(takeUntil(this.untilFn))
                    .subscribe(() => {
            this.modalsProvider.openInfo('Internet connection restored!', 'Connected');
        });

        this.network.onDisconnect()
                    .pipe(takeUntil(this.untilFn))
                    .subscribe(() => {
            this.modalsProvider.openFailure('Internet connection lost!', 'No Connection');
        });
      } else {
        // Init Branch Web SDK
        (function (b, r, a, n, c, h, _, s, d, k) {
          if (!b[n] || !b[n]._q) {
            for (; s < _.length;) {
              c(h, _[s++]);
            }

            d = r.createElement(a);
            d.async = 1;
            d.src = 'https://cdn.branch.io/branch-latest.min.js';
            k = r.getElementsByTagName(a)[0];
            k.parentNode.insertBefore(d, k);
            b[n] = h;
          }
        })(window, document, 'script', 'branch', function (b, r) {
          b[r] = function () {
            b._q.push([r, arguments]);
          };
        }, {
            _q: [],
            _v: 1
          },
          ('addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory ' +
            'credits data deepview deepviewCta first getCode init link logout redeem referrals ' +
            'removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking'
          ).split(' '), 0);

        window['branch'].init('key_live_llNnDliipWziKs53ixz8TokksEiKXA1K');

        const { action, oid, sid, bid } = getParamsFromURL(document.location.href);
        this.handleLink(action, oid, bid, sid);
      }
    });

    if (!this.introService.didIntro && !this.featurePrefsService.isLimitedMobileView()) {
      this.navCtrl.navigateRoot('intro');
      return;
    }
  }

  private async initPush() {
    // Init FCM
    try {
      await this.firebaseService.getDeviceToken();

      console.log('*** Begin listening for notifications');

      this.firebaseService.listenToNotifications().pipe(takeUntil(this.untilFn));
    } catch (e) {
      console.error(`*** Error getting device token ${JSON.stringify(e)} ***`);
    }
  }

  private async handleLink(action: string, oid: string, bid: string, sid: string) {

    if ((Constants.DEEP_LINK_ACTION_GOTO_OFFER === action) && action && oid && sid) {
      // Navigate to offer detail page if the user is signed in. Otherwise,
      // save the offer and share id's in the session for use when the user
      // does log in.
      if (this.authService.isLoggedIn()) {
        await this.navCtrl.navigateForward('/offers/' + oid + '/detail/' + sid);
      } else {
        console.log('*** User not signed in, saving deep link params for when they do ***');
        sessionStorage.setItem(Constants.SHARED_OFFER_ID_KEY, oid);
        sessionStorage.setItem(Constants.SHARED_OFFER_SPREADER_ID_KEY, sid);

        setTimeout(async () => {
          const hasAccount = await this.modalsProvider.openConfirm('To view the offer, you need to sign in. Do you have a Karrot account?');
          if (!hasAccount) {
            await this.navCtrl.navigateForward('/auth/register');
          } else {
            await this.navCtrl.navigateForward('/auth');
          }
        }, 500);

      }
    } else if ((Constants.DEEP_LINK_ACTION_REGISTER === action) && action && bid) {
      // Handle registration deep link
      if (!this.authService.isLoggedIn()) {
        sessionStorage.setItem(Constants.SHARED_BUSINESS_ID_KEY, bid);
        await this.navCtrl.navigateForward('/auth/register');
      } else if (bid) {
        // They're signed in, add the business to their favorites
        await this.businessService.handleBusinessFavorite(Number(bid));
      }
    } else if (action) {
      sessionStorage.setItem(Constants.DEEP_LINK_ACTION, action);
    }
  }

  /**
	 * Initializes the Branch.io deep links SDK
	 */
  private branchInit() {

    console.log('*** Initiating Branch ***');
    const Branch = window['Branch'];

    if (!Branch) {
      console.log('*** Branch subsystem not found! ***');
      return;
    }

    Branch.initSession().then(async deepLink => {
      const szDeepLink = JSON.stringify(deepLink);
      console.log('Processing deep link: ' + szDeepLink);

      try {
        await this.firebaseService.logDeepLinkEvent(szDeepLink);
      } catch (e) {
        console.log('Error sending deep link event to Firebase');
      }

      if (deepLink['+clicked_branch_link']) {
        const { action, offer_id, share_id, business_id } = deepLink;
        this.handleLink(action, offer_id, business_id, share_id);
      } else if (deepLink['+non_branch_link']) {
        const { action, offer_id, share_id, b } = getParamsFromURL(deepLink['+non_branch_link']);
        this.handleLink(action, offer_id, b, share_id);
      }
    });
  }

  ngOnDestroy() {
    this.untilFn.next();
    this.untilFn.complete();
  }
}
