import { Component, OnInit } from '@angular/core';
import { CommonModalPage } from 'src/app/shared/components/common.modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'modal-create-contact',
  templateUrl: './create-contact.modal.html',
  styleUrls: ['./create-contact.modal.scss'],
})
export class CreateContactModal extends CommonModalPage implements OnInit {
  shareType: 'sms' | 'email';

  formGroup: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    const formObj = {
      firstName: ['', Validators.compose([Validators.minLength(2), Validators.maxLength(30), Validators.required])],
      lastName: ['', Validators.compose([Validators.maxLength(30)])]
    };

    if (this.shareType === 'email') {
      formObj['email'] = ['', Validators.compose([Validators.maxLength(50), Validators.email, Validators.required])];
    }

    if (this.shareType === 'sms') {
      formObj['mobile'] = ['', Validators.compose([Validators.pattern(''), Validators.required])];
    }

    this.formGroup = this.formBuilder.group(formObj);
  }

}
