import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ScanRedeemModal } from './scan.redeem.modal';
import { QRCodeModule, RatingModule } from 'ionic4-kits';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    QRCodeModule,
    RatingModule
  ],
  declarations: [ScanRedeemModal],
  entryComponents: [
    ScanRedeemModal
  ],
  exports: [
    ScanRedeemModal
  ]
})
export class ScanRedeemModalModule {}
