import { Component } from '@angular/core';
import { CommonModalPage } from 'src/app/shared/components/common.modal';

@Component({
  selector: 'modal-comment',
  templateUrl: './comment.modal.html',
  styleUrls: ['./comment.modal.scss'],
})
export class CommentModal extends CommonModalPage {

}
